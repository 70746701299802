
<template>
	<div id="global-control" style="height: 100%"></div>
</template>
<script>
import { onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {mapActions,mapGetters} from "vuex"
export default {
  name: "Global Control",
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Global Control");
    });
  },
  computed:{
    ...mapGetters({
      user:'Auth/user'
    })
  },
  created(){
      window.selfF = this;
      window.gcapiV2.init({
			accessToken:this.user.token
		}).loadGlobalControlScreen('global-control')
  },
  methods:{
    ...mapActions({
      connectGc:'Auth/connectGc',
      checkdata:'Auth/checkdata'
    }),
      addTokenToAccount(token){
        let data={
          token:token
        }
          this.connectGc(data).then(()=>{
            			 window.gcapiV2.init({
			              accessToken:this.user.token
		              }).loadGlobalControlScreen('global-control')
          }).catch((e)=>{
              console.log(e)
          })
        },
        mcheckdata(){
          this.checkdata()
        } 
  },
  beforeUnmount(){
      window.gcapiV2.cleanUpScripts()
      let scripts=document.querySelectorAll('style[reference="gc_api"]');
      for(let _script of scripts ){
          eval(document.head.removeChild(_script));
      }
  }
};
window.gcapiV2.onAddAccount=(accessToken=>{
    window.selfF.addTokenToAccount(accessToken)
})
</script>